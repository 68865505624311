.hero {
  background-color: var(--color-primary);
  padding: 4rem 0;
  color: white;
}

.hero-content {
  display: grid;
  gap: 2rem;
}

@media (min-width: 768px) {
  .hero-content {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}

.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.hero-description {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.hero-image {
  max-width: 450px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  background-color: #333;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
