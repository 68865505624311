.feedback-thanks-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-100);
}

.feedback-thanks-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
}

.feedback-thanks-card {
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.feedback-thanks-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--color-primary);
  margin-bottom: 1.5rem;
}

.feedback-thanks-message {
  font-size: 1.125rem;
  line-height: 1.6;
  color: var(--color-gray-600);
}

.feedback-thanks-message a {
  color: var(--color-primary);
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.2s;
}

.feedback-thanks-message a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .feedback-thanks-card {
    padding: 2rem;
  }

  .feedback-thanks-title {
    font-size: 2rem;
  }
}
