.entity-feedback-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.entity-feedback-main {
  flex: 1;
}

.entity-feedback-hero {
  background-color: #026440;
  padding: 2rem 1rem;
  color: white;
}

.entity-feedback-hero-content {
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;
}

.entity-feedback-hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.entity-feedback-hero-description {
  font-size: 1.125rem;
  opacity: 0.9;
}

.entity-feedback-form-section {
  padding: 3rem 1rem;
  background-color: rgb(249 250 251);
}

.entity-feedback-form-wrapper {
  max-width: 42rem;
  margin: 0 auto;
}

.entity-feedback-form-container {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
}

.entity-feedback-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.entity-feedback-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.entity-feedback-label {
  font-weight: 500;
  color: rgb(17 24 39);
}

.entity-feedback-star-container {
  display: flex;
  gap: 0.25rem;
}

.entity-feedback-star-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.entity-feedback-star-button:focus {
  outline: none;
}

.entity-feedback-star-disabled {
  cursor: not-allowed;
}

.entity-feedback-star {
  transition: all 0.2s;
}

.entity-feedback-star-active {
  fill: #026440;
  color: #026440;
}

.entity-feedback-star-inactive {
  fill: rgb(229 231 235);
  color: rgb(229 231 235);
}

.entity-feedback-recommend {
  display: flex;
  gap: 1rem;
}

.entity-feedback-recommend-button {
  padding: 0.5rem 1.5rem;
  border: 2px solid #026440;
  border-radius: 0.5rem;
  font-weight: 500;
  color: #026440;
  background-color: transparent;
  transition: all 0.2s;
}

.entity-feedback-recommend-button:hover:not(:disabled) {
  background-color: rgba(2, 100, 64, 0.1);
}

.entity-feedback-recommend-button-active {
  background-color: #026440;
  color: white;
}

.entity-feedback-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.entity-feedback-textarea {
  width: 100%;
  border: 1px solid rgb(209 213 219);
  border-radius: 0.5rem;
  padding: 0.75rem;
  resize: vertical;
  transition: border-color 0.2s;
}

.entity-feedback-textarea:focus {
  outline: none;
  border-color: #026440;
  box-shadow: 0 0 0 1px #026440;
}

.entity-feedback-submit {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: #026440;
  color: white;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s;
}

.entity-feedback-submit:hover:not(:disabled) {
  opacity: 0.9;
}

.entity-feedback-submit-disabled {
  background-color: rgb(156 163 175);
  cursor: not-allowed;
}

.entity-feedback-error {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: rgb(254 242 242);
  border: 1px solid rgb(254 202 202);
  color: rgb(220 38 38);
  border-radius: 0.5rem;
  text-align: center;
}

.entity-feedback-invalid {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}

.entity-feedback-invalid-title {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: rgb(17 24 39);
}

.entity-feedback-invalid-message {
  color: rgb(107 114 128);
}

@media (max-width: 768px) {
  .entity-feedback-hero-title {
    font-size: 2rem;
  }

  .entity-feedback-form-container {
    padding: 1rem;
  }

  .entity-feedback-recommend {
    flex-direction: column;
  }
}
