.audio-player {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
}

.audio-player__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-player__title-container {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

/* Title input styling - consistent across all contexts */
.audio-player .form-field-wrapper {
  width: auto;
  min-width: 300px;
  max-width: 80%;
}

.audio-player .form-field {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-gray-700);
  width: 100%;
}

.audio-player__title {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-gray-700);
  margin-bottom: 1rem;
  text-align: center;
}

.audio-player__controls {
  width: 100%;
  max-width: 80%;
  margin-bottom: 1rem;
}

.audio-player .media-replace-button {
  margin-top: 1rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .audio-player .form-field-wrapper {
    min-width: 240px;
  }
}
