.section-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Base section styles */
.stop-section {
  position: relative;
  width: 100%;
}

/* View mode styles */
.stop-section__content {
  width: 100%;
}

.stop-section__title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: 1rem;
  line-height: 1.3;
}

.stop-section__paragraph {
  font-size: 1.125rem;
  line-height: 1.6;
  color: var(--color-gray-700);
  margin-bottom: 1.5rem;
}

.stop-section__media {
  margin: 1.5rem 0;
  width: 100%;
}

/* Edit mode styles */
.stop-section-edit {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.stop-section-edit__content {
  flex: 1;
  min-width: 0; /* Prevent content from overflowing */
}

/* Drag handle styles */
.stop-section-edit__drag {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: var(--color-gray-400);
  cursor: grab;
  opacity: 0;
  transition: all 0.2s ease;
}

.stop-section-edit:hover .stop-section-edit__drag {
  opacity: 1;
}

.stop-section-edit__drag:active {
  cursor: grabbing;
}

/* Delete button styles */
.stop-section-edit__delete {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background: none;
  border: none;
  color: var(--color-gray-400);
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.stop-section-edit:hover .stop-section-edit__delete {
  opacity: 1;
}

.stop-section-edit__delete:hover {
  color: #dc2626;
  background-color: #fee2e2;
  border-radius: 0.375rem;
}

/* Media-specific styles */
.stop-section__image {
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
}

.stop-section__audio {
  width: 100%;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
}

.stop-section__video {
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
}

/* Editable field styles */
.stop-section-edit .form-field {
  width: 100%;
  margin-bottom: 0;
}

/* Drag and drop styles */
.stop-section-edit.dragging {
  opacity: 0.5;
}

.stop-section-edit.dragging * {
  pointer-events: none;
}
