.error-screen {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
}

.error-screen__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  text-align: center;
}

.error-screen__icon-wrapper {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-screen__icon {
  color: white;
}

.error-screen__brand {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.error-screen__message {
  color: white;
  font-size: 1.125rem;
  opacity: 0.9;
  max-width: 400px;
  line-height: 1.5;
}
