.guide-stop {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
}

.guide-stop:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.guide-stop__header {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: background-color 0.2s;
  min-height: 3rem;
  gap: 0.75rem;
}

.guide-stop__header:hover {
  background-color: var(--color-gray-50);
}

.guide-stop__drag-handle {
  display: flex;
  align-items: center;
  color: var(--color-gray-400);
  cursor: grab;
  padding: 0.25rem;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
  width: 0;
  margin: 0;
  padding: 0;
}

.guide-stop__title-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-gray-700);
  background-color: white;
  border-radius: 0.375rem;
  /* Set initial border color */
  border: 2px solid transparent;
  outline: none;
  transition:
    border-color 0.2s ease,
    box-shadow 0.2s ease;
}

.guide-stop--editing .guide-stop__title-input {
  border-color: #e5e7eb; /* FIXME: Why does var(--color-gray-700) not work?? */
}

.guide-stop--editing .guide-stop__title-input:hover {
  border-color: #d1d5db; /* FIXME: Why does var(--color-gray-700) not work?? */
}

.guide-stop--editing .guide-stop__title-input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(2, 100, 64, 0.1);
}

.guide-stop--editing .guide-stop__drag-handle {
  opacity: 1;
  visibility: visible;
  width: auto;
  margin-right: 0.5rem;
}

.guide-stop__drag-handle:active {
  cursor: grabbing;
}

.guide-stop__title-container {
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;
}

/* Non-editable title */
.guide-stop__title {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-gray-700);
}

.guide-stop__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
}

.guide-stop__delete {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: none;
  background: none;
  color: var(--color-gray-400);
  cursor: pointer;
  transition: color 0.2s;
}

.guide-stop__delete:hover {
  color: var(--color-red-600);
  background-color: var(--color-red-50);
}

.guide-stop__chevron {
  color: var(--color-gray-400);
  transition: transform 0.2s;
}

.guide-stop__chevron--expanded {
  transform: rotate(180deg);
}

.guide-stop__content {
  padding: 1rem;
  border-top: 1px solid var(--color-gray-100);
}

.guide-stop__description {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--color-gray-700);
  margin-bottom: 1rem;
}

.guide-stop__image {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.guide-stop__image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.guide-stop__image-caption {
  margin-top: 0.5rem;
  color: var(--color-gray-600);
  font-size: 0.875rem;
  font-style: italic;
  text-align: center;
  max-width: 90%;
}

/* Audio container */
.guide-stop__audio,
.stop-content-details__audio {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

/* Audio title */
.guide-stop__audio-title,
.stop-content-details__audio-title {
  font-size: 1rem; /* Reduced from 1.125rem */
  font-weight: 500; /* Reduced from 600 */
  color: var(--color-gray-700); /* Less prominent color */
  margin: 0 0 1rem 0;
  max-width: 80%; /* Prevent title from being too wide */
  text-align: center;
}

/* Audio player */
.guide-stop__audio-player,
.stop-content-details__audio-player,
.guide-stop__audio audio,
.stop-content-details__audio audio {
  width: 80%;
  margin: 0 auto;
  border-radius: 0.25rem;
}

/* Audio container when within a section */
.stop-content-details__section .stop-content-details__audio {
  background-color: white; /* Lighter background when nested */
  margin: 0.5rem 0;
  padding: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .guide-stop__audio-player,
  .stop-content-details__audio-player,
  .guide-stop__audio audio,
  .stop-content-details__audio audio {
    width: 100%;
  }

  .guide-stop__audio-title,
  .stop-content-details__audio-title {
    max-width: 100%;
  }
}

/* Also add these to MediaEditor.css for consistency */
.media-editor__preview-image {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.media-editor__preview-caption {
  margin-top: 0.5rem;
  color: var(--color-gray-600);
  font-size: 0.875rem;
  font-style: italic;
}

.guide-stop__add-details-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  background-color: white;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
  color: var(--color-primary);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.guide-stop__add-details-button:hover {
  background-color: var(--color-primary);
  color: white;
}

.guide-stop__details {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--color-gray-200);
}

.guide-stop__details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.guide-stop__details-header h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--color-primary);
  margin: 0;
}

.guide-stop__details-actions {
  display: flex;
  gap: 0.75rem;
}

.guide-stop__details-actions button {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.guide-stop__details-actions button:first-child {
  background-color: white;
  border: 1px solid var(--color-gray-300);
  color: var(--color-gray-600);
}

.guide-stop__details-actions button:last-child {
  background-color: var(--color-primary);
  border: none;
  color: white;
}

.guide-stop__details-error {
  margin: 1rem 0;
  padding: 0.75rem;
  background-color: #fee2e2;
  border-radius: 0.375rem;
  color: #dc2626;
  font-size: 0.875rem;
}
