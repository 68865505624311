.guides-map {
  width: 100%;
  height: 400px;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Improve popup styling */
.guide-popup {
  max-width: 240px;
  padding: 0;
}

.guide-popup__image {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 0.25rem 0.25rem 0 0;
  margin-bottom: 0.75rem;
}

.guide-popup__title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0.75rem 0.5rem;
  color: var(--color-primary);
  line-height: 1.2;
}

.guide-popup__price {
  font-size: 0.875rem;
  color: #4a5568;
  margin: 0 0.75rem 0.75rem;
}

/* Override default leaflet popup styles */
.leaflet-popup-content-wrapper {
  padding: 0;
  border-radius: 0.5rem;
  overflow: hidden;
}

.leaflet-popup-content {
  margin: 0;
  width: 240px !important;
}

.leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .guides-map {
    height: 300px; /* Smaller height on mobile */
    border-radius: 0; /* Optional: remove border radius on mobile */
  }
}
