.process {
  background-color: #f8f9fa;
  padding: 5rem 0;
}

.process-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2rem;
  font-weight: bold;
  color: #1a202c;
}

.process-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .process-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.process-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.process-step-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1a202c;
}

.process-description {
  color: #4a5568;
  line-height: 1.6;
  font-size: 1rem;
  margin: 0;
}
