.guides-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.guides-hero {
  background-color: var(--color-primary);
  color: white;
  padding: 4rem 0;
  margin-bottom: 2rem;
}

.guides-hero__title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.guides-hero__description {
  font-size: 1.25rem;
  opacity: 0.9;
  max-width: 600px;
}

.guides-map-section {
  margin-bottom: 3rem;
  padding: 0 1rem;
}

.guides-grid-section {
  padding: 2rem 1rem;
  flex: 1;
}

.guides-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.guides-loading,
.guides-error {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #4a5568;
}

.guides-error {
  color: #e53e3e;
}

@media (max-width: 768px) {
  .guides-hero__title {
    font-size: 2rem;
  }

  .guides-hero__description {
    font-size: 1.125rem;
  }

  .guides-grid {
    grid-template-columns: 1fr;
  }
}
