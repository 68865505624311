/* src/components/ExperienceContainer.css */
.experience-container {
  max-width: 400px;
  margin: 0 auto;
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 1rem;
  left: 0;
}

.nav-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}

.nav-button:hover {
  color: #007bff;
}

.prev-button {
  text-align: left;
}

.next-button {
  text-align: right;
}

.references {
  width: 100%;
}

.references-toggle {
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.rating {
  margin-top: 1rem;
  text-align: center;
}

.rating h2 {
  margin-bottom: 0.5rem;
}

.thumb-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  margin: 0 0.5rem;
  transition: color 0.3s;
}

.thumb-button.selected {
  color: #007bff;
}
