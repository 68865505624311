.custom-button {
  display: inline-block;
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    box-shadow 0.3s,
    opacity 0.3s;
}

.custom-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4); /* Adjust focus color based on your design */
}

.custom-button:hover {
  opacity: 0.9;
}

.custom-button:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Primary Button */
.custom-button-primary {
  background-color: #026440;
}

.custom-button-primary:hover {
  background-color: #024d36;
}

/* Secondary Button */
.custom-button-secondary {
  background-color: #00897b;
}

.custom-button-secondary:hover {
  background-color: #007065;
}

/* Tertiary Button */
.custom-button-tertiary {
  background-color: #444444;
  color: #fff;
  border: none;
}

.custom-button-tertiary:hover {
  background-color: #666666;
}

/* Accent Button */
.custom-button-accent {
  background-color: #1565c0;
}

.custom-button-accent:hover {
  background-color: #104a8c;
}

/* Plain Button */
.custom-button-plain {
  background-color: transparent; /* Make the background transparent */
  color: #444444; /* Set the font color to deep gray */
  border: 2px solid #444444; /* Set a 2px solid border with the same deep gray color */
}

/* Small Button */
.custom-button-small {
  padding: 6px 12px;
  font-size: 14px;
}

/* Large Button */
.custom-button-large {
  padding: 12px 64px;
  font-size: 18px;
}

.custom-button-disabled {
  background-color: #dfdddd !important; /* Light gray background */
  color: #a0a0a0 !important; /* Light gray text */
  cursor: not-allowed; /* Change cursor to not-allowed */
  opacity: 0.6; /* Add opacity to make the button look disabled */
}

.custom-button-clicked-disabled {
  background-color: #c0c0c0; /* Darker gray */
}

.button-wrapper {
  display: inline-block;
  cursor: pointer;
}

.button-wrapper:disabled {
  cursor: not-allowed;
}
