.card-carousel-wrapper {
  position: relative;
  padding-bottom: 50px;
}

.card-carousel {
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: static;
}

.carousel-slide {
  padding: 0 10px;
  height: 400px;
}

.slide-inner {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.slide-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.slide-content {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide-title {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.slide-description {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
}

/* Styling for slides without images */
.slide-inner.no-image {
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.slide-inner.no-image .slide-content {
  padding: 30px;
}

.navigate-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;
  margin-top: 20px;
}

.navigate-button:hover {
  background-color: #0056b3;
}

.slide-inner.no-image .slide-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.slide-inner.no-image .slide-description {
  font-size: 18px;
  margin-bottom: 30px;
}

/* Customize the dots */
.custom-card-carousel .slick-dots {
  bottom: -40px;
  z-index: 1;
}

.custom-card-carousel .slick-dots li {
  margin: 0 4px;
}

.custom-card-carousel .slick-dots li button:before {
  font-size: 12px;
  color: #ccc;
  opacity: 0.7;
}

.custom-card-carousel .slick-dots li.slick-active button:before {
  color: #000;
  opacity: 1;
}

.custom-card-carousel .slick-slide {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.custom-card-carousel .slick-center {
  opacity: 1;
}

.custom-card-carousel .slick-list {
  overflow: visible;
  margin-bottom: 40px;
}
