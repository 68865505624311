/* src/components/Page.css */
.page {
  display: flex;
  flex-direction: column;
}

.carousel img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.audio {
  margin-bottom: 1rem;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.option {
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.option.correct {
  background: #4caf50;
  color: #fff;
}

.option.incorrect {
  background: #f44336;
  color: #fff;
}

.explanation {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #333;
}
