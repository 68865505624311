/* Base form field styles */
.form-field {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  color: var(--color-gray-700);
  background-color: white;
  border-radius: 0.375rem;
  border: 2px solid #e5e7eb;
  outline: none;
  transition:
    border-color 0.2s ease,
    box-shadow 0.2s ease;
}

.form-field:hover {
  border-color: #d1d5db;
}

.form-field:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(2, 100, 64, 0.1);
}

/* Textarea specific styles */
.form-field--multiline {
  min-height: 100px;
  resize: vertical;
  line-height: 1.5;
}

/* Label styles */
.form-field-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-gray-700);
  margin-bottom: 0.5rem;
}

/* Field wrapper */
.form-field-wrapper {
  margin-bottom: 0rem;
}

/* Error state */
.form-field--error {
  border-color: var(--color-red-500);
}

.form-field--error:hover,
.form-field--error:focus {
  border-color: var(--color-red-600);
  box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.1);
}

/* Disabled state */
.form-field:disabled {
  background-color: var(--color-gray-50);
  cursor: not-allowed;
  opacity: 0.7;
}
