.tour-details-feature-icon-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.tour-details-feature-primary {
  background-color: rgba(2, 100, 64, 0.1); /* Using brand primary color */
}

.tour-details-feature-icon-primary {
  color: var(--color-primary); /* Using project's primary color variable */
}

.tour-feature-card {
  background-color: white;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.tour-feature-card-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--color-primary);
}

.tour-feature-card-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 0;
}
