.creators-portal {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.creators-portal__main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.creators-portal__hero {
  position: relative;
  height: min(400px, 50vh);
  background-color: var(--color-primary);
  overflow: hidden;
}

.creators-portal__hero-background {
  position: absolute;
  inset: 0;
}

.creators-portal__hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.creators-portal__hero-overlay {
  position: absolute;
  inset: 0;
  background-color: var(--color-primary);
  opacity: 0.7;
}

.creators-portal__hero-content {
  position: relative;
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 2rem 1rem;
}

.creators-portal__hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.creators-portal__hero-description {
  font-size: 1.25rem;
  max-width: 42rem;
  margin: 0 auto;
  opacity: 0.8;
}

.creators-portal__auth {
  max-width: 28rem;
  margin: -5rem auto auto;
  position: relative;
  z-index: 20;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.creators-portal__auth-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #1a202c;
  margin-bottom: 2rem;
}

.creators-portal__auth-buttons {
  display: flex;
  flex-direction: column;
}

.creators-portal__auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  color: #4a5568;
  font-weight: 500;
  transition: all 0.2s;
}

.creators-portal__auth-button:hover {
  background-color: #f7fafc;
  transform: translateY(-1px);
}

.creators-portal__auth-button-icon {
  width: 1.25rem;
  height: 1.25rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 768px) {
  .creators-portal__hero-title {
    font-size: 3rem;
  }
}
