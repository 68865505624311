.creator-dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.creator-dashboard__main {
  flex: 1;
  background-color: var(--color-gray-100);
}

.creator-dashboard__content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.creator-dashboard__stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.creator-dashboard__stat-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.creator-dashboard__stat-title {
  font-size: 0.875rem;
  color: var(--color-gray-600);
  margin-bottom: 0.5rem;
}

.creator-dashboard__stat-value {
  font-size: 2rem;
  font-weight: bold;
  color: var(--color-primary);
}

.creator-dashboard__guides {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.creator-dashboard__guide-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
}

.creator-dashboard__guide-card:hover {
  transform: translateY(-4px);
}

.creator-dashboard__guide-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.creator-dashboard__guide-content {
  padding: 1.5rem;
}

.creator-dashboard__guide-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--color-primary);
}

.creator-dashboard__guide-price {
  color: var(--color-gray-600);
}

/* Overview editing styles */
.creator-dashboard__overview {
  padding: 1.5rem;
  max-width: 500px;
  margin: 0 auto;
}

.creator-dashboard__overview-header {
  margin-bottom: 2rem;
}

.creator-dashboard__overview-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--color-primary);
}

/* Stop styles with drag and drop */
.creator-dashboard__stops {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.creator-dashboard__stop {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
}

.creator-dashboard__stop:hover {
  background-color: var(--color-gray-100);
}

.creator-dashboard__stop-header {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
}

.creator-dashboard__stop-drag-handle {
  color: var(--color-gray-400);
  cursor: grab;
  padding: 0.25rem;
  /* border-radius: 0.25rem; */
  /* transition: background-color 0.2s; */
  margin-right: 1rem;
}

.creator-dashboard__stop-drag-handle:hover {
  background-color: var(--color-gray-200);
  color: var(--color-gray-600);
}

.creator-dashboard__stop-drag-handle:active {
  cursor: grabbing;
  /* background-color: var(--color-gray-300); */
}

.creator-dashboard__stop-title {
  flex: 1;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
}

.creator-dashboard__stop-chevron {
  color: var(--color-gray-400);
  transition: transform 0.2s;
}

.creator-dashboard__stop-chevron--expanded {
  transform: rotate(180deg);
}

.creator-dashboard__stop-content {
  padding: 0 1rem 1rem 1rem;
  display: none;
}

.creator-dashboard__stop-content--expanded {
  display: block;
}

/* Media content styles */
.creator-dashboard__stop-media {
  margin-top: 1rem;
}

.creator-dashboard__stop-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem;
}

.creator-dashboard__stop-audio {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--color-gray-100);
  border-radius: 0.5rem;
}

.creator-dashboard__stop-audio-player {
  width: 100%;
  margin-top: 0.5rem;
}

.creator-dashboard__stop-transcript {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--color-gray-200);
}

.creator-dashboard__stop-video {
  width: 100%;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

.creator-dashboard__stop-video-title {
  margin-top: 0.5rem;
  font-weight: bold;
}

.creator-dashboard__stop-location {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: var(--color-gray-100);
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .creator-dashboard__stats {
    grid-template-columns: 1fr;
  }

  .creator-dashboard__guides {
    grid-template-columns: 1fr;
  }

  .creator-dashboard__main {
    padding: 1rem;
  }

  .creator-dashboard__header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .creator-dashboard__create-button {
    width: 100%;
    justify-content: center;
  }
}

.creator-dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.creator-dashboard__title {
  font-size: 2rem;
  font-weight: 600;
  color: #026440;
  margin: 0;
}

.creator-dashboard__create-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #026440;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}

.creator-dashboard__create-button:hover {
  opacity: 0.9;
}

.creator-dashboard__loading,
.creator-dashboard__error {
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.creator-dashboard__error {
  color: #dc2626;
  background-color: #fee2e2;
}
