/* Root container */
.guide-details-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Hero Section */
.guide-details-hero {
  background-color: var(--color-primary);
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  color: white;
}

.guide-details-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(2, 100, 64, 0.5);
  z-index: 1;
}

.guide-details-hero__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.guide-details-hero__content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  padding: 0 1rem;
  margin: 0 auto;
  width: 100%;
}

.guide-details-hero__title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.guide-details-hero__description {
  font-size: 1.25rem;
  opacity: 0.9;
}

/* Main Content Layout */
.guide-details__container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.guide-details__content-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  padding: 3rem 0;
}

/* About Section */
.guide-details-about {
  margin-bottom: 3rem;
}

.guide-details-about__title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: var(--color-primary);
}

.guide-details-about__description {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 2rem;
}

.guide-details-about__image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.guide-details-about__image {
  width: 100%;
  height: 240px;
  border-radius: 8px;
  object-fit: cover;
}

/* Testimonials Section */
.guide-details-testimonials {
  margin-top: 3rem;
}

.guide-details-testimonials__title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: var(--color-primary);
}

.guide-details-testimonials__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.guide-details-testimonials__card {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
}

.guide-details-testimonials__text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #333;
}

.guide-details-testimonials__author {
  border-top: 1px solid #e9ecef;
  padding-top: 1rem;
}

.guide-details-testimonials__author-name {
  font-weight: bold;
  color: #333;
}

.guide-details-testimonials__author-date {
  font-size: 0.875rem;
  color: #666;
}

/* Sidebar */
.guide-details-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Creator Card */
.guide-details-creator {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.guide-details-creator__header {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.guide-details-creator__image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.guide-details-creator__title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  color: var(--color-primary);
}

.guide-details-creator__credentials {
  font-size: 0.875rem;
  color: #666;
}

.guide-details-creator__bio {
  font-size: 0.875rem;
  line-height: 1.6;
  color: #333;
}

/* CTA Card */
.guide-details-cta {
  background-color: #f0f7f4;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.guide-details-cta__title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.guide-details-cta__description {
  margin-bottom: 1.5rem;
  color: #333;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .guide-details__content-grid {
    grid-template-columns: 1fr;
  }

  .guide-details-hero__title {
    font-size: 2rem;
  }

  .guide-details-about__image-grid {
    grid-template-columns: 1fr;
  }

  .guide-details-testimonials__grid {
    grid-template-columns: 1fr;
  }
}
