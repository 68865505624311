.section-creation {
  margin-top: 1rem;
}

.section-creation__controls {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
}

.section-creation__button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  color: var(--color-gray-600);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.section-creation__button:hover {
  background-color: #f8f9fa;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.section-creation__label {
  display: none;
}

@media (min-width: 640px) {
  .section-creation__label {
    display: inline;
  }
}
