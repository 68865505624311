.image-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 42rem;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.image-viewer__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-viewer__image {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.image-viewer__caption {
  font-size: 0.875rem;
  color: var(--color-gray-600);
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  max-width: 600px;
}

.image-viewer .form-field {
  text-align: center;
  margin: 0.5rem 0 1rem;
  font-size: 0.875rem;
  color: var(--color-gray-600);
  width: 100%;
}

.image-viewer .form-field-wrapper {
  width: 100%;
}

.image-viewer__replace {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  color: var(--color-gray-600);
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 0.5rem;
}

.image-viewer__replace:hover {
  background-color: #f8f9fa;
  color: var(--color-gray-700);
}

.image-viewer__replace:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
