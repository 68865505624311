.guide-manager {
  padding: 2rem 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.guide-manager__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.guide-manager__back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  color: #64748b;
  cursor: pointer;
  transition: color 0.2s;
}

.guide-manager__back-button:hover {
  color: #026440;
}

.guide-manager__title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--color-primary);
}

.guide-manager__actions {
  display: flex;
  gap: 1rem;
}

.guide-manager__error {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  border-radius: 0.5rem;
  color: #dc2626;
}

.guide-manager__content {
  max-width: 500px; /* Match overview width */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.guide-manager__stops {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .guide-manager {
    padding: 1rem;
  }

  .guide-manager__header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .guide-manager__actions {
    width: 100%;
  }

  .guide-manager__actions button {
    flex: 1;
  }
}
