.tile {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;
  position: relative;
}

.tile:hover {
  transform: translateY(-5px);
}

.tile .image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.tile .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.tile .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.8rem;
  color: white;
}

.tile .title {
  font-size: clamp(0.8rem, 4vw, 1.2rem);
  font-weight: bold;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.tile .description {
  font-size: clamp(0.7rem, 3vw, 0.9rem);
  opacity: 0.9;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tile.big {
  grid-column: span 2;
  height: 400px;
}

.tile.small {
  grid-column: span 1;
  height: 200px;
}

.tile.no-description .image-container {
  height: 100%;
}

.tile.no-description .content {
  background: rgba(0, 0, 0, 0.6);
  padding: 0.5rem;
}

.tile.no-description .title {
  margin-bottom: 0;
}

.premium-badge {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.tile.grayed-out::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.8); /* Gray and translucent */
  z-index: 1;
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.2em;
  text-align: center;
  z-index: 2;
  font-weight: bold;
}
