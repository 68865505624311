.submit-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.submit-button:focus {
  outline: none;
}
