.form-section {
  padding: 5rem 0;
  background-color: white;
}

.form-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: #1a202c;
}

.signup-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 2.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #1a202c;
  font-size: 1rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #1a202c;
  transition: border-color 0.2s;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--color-primary);
}

.submit-button {
  background-color: var(--color-primary);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  font-weight: 500;
  transition: opacity 0.2s;
}

.submit-button:hover {
  opacity: 0.9;
}

.submit-button:disabled {
  background-color: var(--color-primary);
  opacity: 0.7;
  cursor: not-allowed;
}

.submit-button.submitting {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.form-group input:disabled,
.form-group textarea:disabled {
  background-color: #f7fafc;
  cursor: not-allowed;
}

.button-text {
  display: inline-block;
  vertical-align: middle;
}
