@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.slideSignedInLanding img {
  margin: 0 auto;
}

.slideSignedInLanding {
  transform: scale(0.55);
  transition: transform 300ms;
  opacity: 0.5;
  position: relative;
}

.activeSlideSignedInLanding {
  transform: scale(0.9);
  opacity: 1;
  z-index: 10;
}

.previousSlideSignedInLanding {
  transform: scale(0.75);
  opacity: 0.8;
  z-index: 10;
}
