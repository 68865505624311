.guide-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.guide-card-link:hover {
  text-decoration: none;
  color: inherit;
}

.guide-card {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease;
}

.guide-card:hover {
  transform: translateY(-4px);
}

.guide-card__image-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.guide-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.guide-card__content {
  padding: 1.5rem;
}

.guide-card__title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--color-primary);
}

.guide-card__description {
  font-size: 0.875rem;
  color: #4a5568;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.guide-card__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.guide-card__tag {
  background-color: #e2e8f0;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  color: #4a5568;
}

.guide-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.guide-card__creator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.guide-card__creator-image {
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  object-fit: cover;
}

.guide-card__creator-name {
  font-size: 0.875rem;
  color: #4a5568;
}

.guide-card__price {
  font-weight: bold;
  color: var(--color-primary);
}
