.progress-bar-container {
  display: flex;
  align-items: flex-start; /* Changed from center to align with the top of the text */
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  padding: 0 10px;
}

.progress-line-full {
  position: absolute;
  top: 5px;
  left: 50px;
  right: 50px;
  height: 6px;
  z-index: 0;
  background-color: #ccc;
}

.progress-line-completed {
  position: absolute;
  top: 5px;
  left: 50px;
  height: 6px;
  z-index: 1;
  background-color: #026440;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 80px; /* Set a fixed width for each step */
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ccc;
  margin-bottom: 10px;
}

.label {
  text-align: center;
  white-space: normal; /* Allow wrapping */
  margin-bottom: 2px;
  font-size: 16px; /* Adjust as needed */
  line-height: 1; /* Adjust line height for better readability */
  width: 80%; /* Use full width of step-container */
}

.subtext {
  font-size: 12px;
  text-align: center;
  white-space: normal; /* Allow wrapping */
  width: 80%; /* Use full width of step-container */
  line-height: 1;
  margin-top: 2px;
  font-weight: bold;
}
