.responsive-box {
  max-width: 400px;
  width: 97%;
  margin: auto;
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px; /* Ensure no unnecessary margin between rows */
  padding: 0px;
}

.left {
  /* Remove static flex assignment, handle in JS */
  text-align: left; /* default alignment for left */
}

.right {
  text-align: right;
}

.snug {
  border: none;
  padding: 0;
  /* align-items: left; */
}

.snug .row {
  margin-bottom: 0;
}

/* Center align a single child */
.row.single-child .left,
.row.single-child .right {
  flex: 1;
  text-align: center;
}
