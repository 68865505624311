.media-dropzone {
  width: 100%;
  padding: 2rem;
  border: 2px dashed var(--color-gray-200);
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.media-dropzone:hover,
.media-dropzone--active {
  border-color: var(--color-primary);
  background-color: rgba(2, 100, 64, 0.05);
}

.media-dropzone__icon {
  color: var(--color-gray-400);
}

.media-dropzone__text {
  font-size: 0.875rem;
  color: var(--color-gray-600);
  text-align: center;
}

/* Shared error styles */
.media-error {
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.75rem;
  background-color: #fee2e2;
  border-radius: 0.5rem;
  color: #dc2626;
  font-size: 0.875rem;
  text-align: center;
}

/* Replace button */
.media-replace-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  color: var(--color-gray-600);
  cursor: pointer; /* Add cursor pointer */
  transition: all 0.2s;
  margin-bottom: 1rem;
}

.media-replace-button:hover {
  background-color: #f8f9fa;
  color: var(--color-gray-700);
}

.media-remove-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 50%;
  color: var(--color-gray-600);
  cursor: pointer; /* Add cursor pointer */
  transition: all 0.2s;
  z-index: 1; /* Ensure button is clickable */
}

.media-remove-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: var(--color-gray-700);
}

.media-dropzone__input-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
