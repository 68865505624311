.video-player {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

.video-player__title {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-gray-700);
  margin-bottom: 1rem;
  text-align: center;
}

.video-player__content {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-player__video {
  width: 100%;
  max-width: 80%;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.video-player .media-dropzone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Error state */
.video-player__error {
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.75rem;
  background-color: #fee2e2;
  border-radius: 0.5rem;
  color: #dc2626;
  font-size: 0.875rem;
  text-align: center;
}
