.guide-overview {
  max-width: 500px;
  margin: 0 auto;
}

.guide-overview--editing {
  padding: 1rem;
}

.guide-overview__header {
  margin-bottom: 1.5rem;
}

.guide-overview__content {
  margin-bottom: 2rem;
}
.guide-overview__title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-primary);
  margin-bottom: 16px;
}

.guide-overview__description {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--color-gray-600);
  margin-bottom: 16px;
}

.guide-overview__edit-actions {
  display: flex;
  gap: 1rem;
}

/* Editable field styles */
.guide-overview__editable-field {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

/* Base input/textarea styles */
.guide-overview__editable-input,
.guide-overview__editable-textarea {
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 8px 12px;
  font: inherit;
}

/* Title input specific styles */
.guide-overview__editable-input {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-primary);
}

/* Description textarea specific styles */
.guide-overview__editable-textarea {
  font-size: 1rem;
  line-height: 1.6;
  min-height: 120px;
  resize: vertical;
}

/* Focus states */
.guide-overview__editable-input:focus,
.guide-overview__editable-textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(2, 100, 64, 0.1);
}

/* Container styles */
.guide-overview__editable-field {
  margin-bottom: 1.5rem;
}
