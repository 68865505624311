.features {
  padding: 4rem 0;
}

.features-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  gap: 2rem;
}

@media (min-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.feature-card {
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  border: 1px solid #e5e7eb;
}

.feature-card:hover {
  transform: translateY(-4px);
}

.feature-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
