.guide-list__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem 0;
}

.guide-list__tile {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  cursor: pointer;
}

.guide-list__tile:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.guide-list__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.guide-list__content {
  padding: 1.5rem;
}

.guide-list__title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #026440;
  margin: 0 0 0.5rem 0;
}

.guide-list__description {
  color: #64748b;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.guide-list__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.guide-list__tag {
  background-color: #f1f5f9;
  color: #64748b;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
}

.guide-list__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.guide-list__creator {
  font-size: 0.875rem;
  color: #64748b;
}

.guide-list__price {
  font-weight: 600;
  color: #026440;
}

.guide-list__loading,
.guide-list__error,
.guide-list__empty {
  text-align: center;
  padding: 3rem;
  color: #64748b;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.guide-list__error {
  color: #dc2626;
  background-color: #fee2e2;
}

.guide-list__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.guide-list__status {
  background-color: #fef3c7;
  color: #92400e;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;
}

.guide-list__title {
  margin: 0;
  flex: 1;
  min-width: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .guide-list__grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .guide-list__content {
    padding: 1rem;
  }
}
