.navigation {
  background-color: var(--color-primary);
  padding: 1rem 0;
  width: 100%;
}

.navigation-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-brand {
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: bold;
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navigation-link {
  color: white;
  text-decoration: none;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
}

@media (max-width: 768px) {
  .navigation {
    padding: 0.75rem 0;
  }

  .nav-menu {
    display: none;
  }

  .menu-toggle {
    display: block;
  }

  .mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.95);
    height: 100vh;
    z-index: 9999;
  }

  .mobile-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 1.5rem;
  }

  .mobile-menu .navigation-link {
    font-size: 1.125rem;
    margin: 0;
    padding: 0;
  }
}

.mobile-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
}
