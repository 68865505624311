.media-uploader {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.media-uploader__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.media-uploader__title {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-gray-700);
  margin: 0;
}

.media-uploader__info {
  font-size: 0.875rem;
  color: var(--color-gray-500);
}

/* Hide the actual file input but keep it accessible */
.media-uploader__input-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.media-uploader__dropzone {
  padding: 2rem;
  border: 2px dashed var(--color-gray-200);
  border-radius: 0.5rem;
  background-color: var(--color-gray-50);
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.media-uploader__dropzone:hover,
.media-uploader__dropzone--active,
.media-uploader__dropzone:focus {
  border-color: var(--color-primary);
  background-color: rgba(2, 100, 64, 0.05);
  outline: none;
}

.media-uploader__input {
  position: absolute;
  inset: 0;
  opacity: 0;
  cursor: pointer;
}

.media-uploader__icon {
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.media-uploader__text {
  color: var(--color-gray-600);
}

.media-uploader__preview {
  width: 100%;
  max-width: 42rem; /* Match the standard content width */
  margin: 0 auto;
}

.media-uploader__preview-image {
  width: 100%;
  height: auto;
  display: block;
}

.media-uploader__caption {
  padding: 0.5rem;
  margin: 0;
  background-color: var(--color-gray-50);
  font-size: 0.875rem;
  color: var(--color-gray-600);
}

.media-uploader__audio-player {
  padding: 1.5rem;
  background-color: var(--color-gray-50);
  border-radius: 0.5rem;
}

.media-uploader__audio-title {
  margin: 0 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-gray-700);
  text-align: center;
}

.media-uploader__audio {
  width: 100%;
}

.media-uploader__video {
  width: 100%;
  display: block;
}

.media-uploader__remove {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.media-uploader__remove:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.media-uploader__replace {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-gray-50);
  border: none;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: var(--color-gray-600);
  cursor: pointer;
  transition: all 0.2s;
}

.media-uploader__replace:hover {
  background-color: var(--color-gray-100);
  color: var(--color-gray-700);
}

.media-uploader__uploading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-gray-600);
}

.media-uploader__spinner {
  animation: spin 1s linear infinite;
}

.media-uploader__error {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #fee2e2;
  border-radius: 0.25rem;
  color: #dc2626;
  font-size: 0.875rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
