.benefits {
  padding: 5rem 0;
}

.benefits-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2rem;
  font-weight: bold;
}

.benefits-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.benefit-card {
  border-left: 4px solid var(--color-primary);
  padding: 1.5rem;
  transition: transform 0.2s;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.benefit-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.benefit-description {
  color: #4a5568;
  line-height: 1.6;
}
