/* src/components/TileGrid.css */
.grid-wrapper {
  /* display: flex; */
  justify-content: center;
  padding: 1rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  max-width: 400px;
}
