.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
  text-decoration: none;
  transition:
    background-color 0.2s,
    opacity 0.2s;
  cursor: pointer;
  border: none;
}

.button:hover {
  opacity: 0.9;
}

.button-light {
  background-color: white;
  color: var(--color-primary);
}

.button-primary {
  background-color: var(--color-primary);
  color: white;
}
