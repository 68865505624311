.cta {
  padding: 5rem 0;
  text-align: center;
  background-color: #f8f9fa;
}

.cta-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.cta-text {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.cta-link {
  color: var(--color-primary);
  text-decoration: none;
  border-bottom: 2px solid var(--color-primary);
  font-weight: 500;
  transition: opacity 0.2s;
}

.cta-link:hover {
  opacity: 0.7;
}
