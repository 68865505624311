.testimonials {
  background-color: var(--color-primary);
  color: white;
  padding: 5rem 0;
}

.testimonials-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.testimonials-grid {
  display: grid;
  gap: 2rem;
}

@media (min-width: 768px) {
  .testimonials-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.testimonial-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.testimonial-text {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e272c;
}

.testimonial-author {
  font-weight: bold;
  margin-bottom: 0.25rem;
  color: #1e272c;
}

.testimonial-role {
  color: #666;
  font-size: 0.875rem;
}
