.navbar-brand {
  font-size: 1.5rem; /* Larger brand font */
  color: #fff;
  font-weight: bold; /* Make the brand text bold */
}

.navbar-brand:hover {
  color: #fff;
}

.nav-link {
  font-size: 1.1rem; /* Increase nav link text size */
  margin-right: 1rem; /* Spacing between nav items */
  color: white;
  background-color: #026440; /* Set background color for nav links when not collapsed */
}

/* Style for the navbar toggle (hamburger icon) */
.navbar-toggler {
  border: none; /* Remove default border */
}

/* Customizing the navbar collapse for better spacing and appearance */
.navbar-collapse {
  justify-content: flex-end; /* Align nav items to the right */
}

.custom-navbar {
  background-color: #026440;
}

/* Change the color of the navbar toggle (hamburger icon) */
.navbar-toggler-icon {
  filter: invert(1); /* This inverts the color of the hamburger icon to white */
}

/* Specific styles when the navbar is collapsed */
.navbar-collapse {
  background-color: #333 !important; /* Set background color of the expanded menu area */
  padding: 0.5rem; /* Reduced padding */
}

.navbar-nav .nav-link {
  background-color: #333 !important; /* Set background color for menu items */
  color: white !important; /* Set text color for menu items */
}

/* Reset background color for nav links when navbar is not collapsed */
@media (min-width: 992px) {
  .navbar-collapse {
    background-color: transparent !important; /* Make the background of the collapse area transparent */
  }

  .navbar-nav .nav-link {
    background-color: #026440 !important; /* Set the background color for nav links to green */
  }
}

.navbar {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  box-shadow: none;
}
