.auth-nav {
  background-color: var(--color-primary);
  padding: 1rem 0;
  width: 100%;
}

.auth-nav__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-nav__brand {
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: bold;
}

.auth-nav__menu {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.auth-nav__welcome {
  color: white;
  font-size: 1rem;
}

.auth-nav__menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
}

@media (max-width: 768px) {
  .auth-nav {
    padding: 0.75rem 0;
  }

  .auth-nav__menu {
    display: none;
  }

  .auth-nav__menu-toggle {
    display: block;
  }

  .auth-nav__mobile-menu {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.95);
    height: 100vh;
    z-index: 1000;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
  }

  .auth-nav__mobile-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
  }

  .auth-nav__mobile-menu .auth-nav__welcome {
    font-size: 1.125rem;
  }
}
