.footer {
  background-color: var(--color-primary);
  padding: 1rem 0;
  color: rgba(255, 255, 255, 0.7);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  margin-left: 2rem;
  transition: color 0.2s;
}

.footer-link:hover {
  color: white;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .footer-link {
    margin-left: 0;
  }
}
