/* Base layout */
.location-editor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
}

/* Location display/controls in edit mode */
.location-editor__display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
}

.location-editor__info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #495057;
}

.location-editor__coordinates {
  font-family: monospace;
  font-size: 0.875rem;
}

.location-editor__map-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #495057;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.location-editor__map-button:hover {
  background-color: #f8f9fa;
  border-color: #adb5bd;
}

/* Map containers */
.location-editor__map-container,
.location-editor__preview-map-container {
  height: 300px;
  border-radius: 0.5rem;
  overflow: hidden;
}

.location-editor__map,
.location-editor__preview-map {
  height: 100%;
  width: 100%;
}

/* Empty state for edit mode */
.location-editor__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 2rem;
  background-color: #f8f9fa;
  border: 2px dashed #ced4da;
  border-radius: 0.5rem;
  color: #6c757d;
  cursor: pointer;
  transition: all 0.2s;
}

.location-editor__empty-state:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
}

/* Location picker modal */
.location-editor-modal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.location-editor-modal__content {
  width: 100%;
  max-width: 48rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.location-editor-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.location-editor-modal__title {
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
  color: #212529;
}

.location-editor-modal__close {
  padding: 0.25rem;
  font-size: 1.5rem;
  color: #6c757d;
  background: none;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  line-height: 1;
}

.location-editor-modal__body {
  padding: 1rem;
}

.location-editor-modal__map-container {
  height: 400px;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.location-editor-modal__map {
  height: 100%;
  width: 100%;
}

.location-editor-modal__help {
  text-align: center;
  font-size: 0.875rem;
  color: #6c757d;
}

.location-editor-modal__footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
}

.location-editor-modal__cancel,
.location-editor-modal__confirm {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.location-editor-modal__cancel {
  color: #495057;
  background-color: white;
  border: 1px solid #ced4da;
}

.location-editor-modal__cancel:hover {
  background-color: #f8f9fa;
  border-color: #adb5bd;
}

.location-editor-modal__confirm {
  color: white;
  background-color: #026440;
  border: none;
}

.location-editor-modal__confirm:hover {
  background-color: #015535;
}

/* Popup styles */
.location-editor__popup {
  padding: 0.5rem;
  font-size: 0.875rem;
}

.location-editor__popup p {
  margin: 0.25rem 0;
}
